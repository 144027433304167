import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Meta from '../components/Meta'
import NewsItem from '../components/Newsitem'
import './news.scss'

const NewsPage = ({ data, location }) => {
  return (
    <Layout>
      <Meta
        data={{
          pathName: location.pathname,
          title: 'News',
          description: 'News from Studio WillemsPeeters',
        }}
      />
      <div className="container">
        <ul className="newslist columns">
          {data.allPrismicNews.edges.map(({ node }) => (
            <NewsItem key={node.prismicId} data={node.data} />
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query newsQuery {
    allPrismicNews(sort: { order: DESC, fields: data___date }) {
      edges {
        node {
          prismicId
          data {
            text {
              html
            }
            text_date: date(formatString: "MMMM YYYY")
            number_date: date(formatString: "YYYY-MM")
            image {
              fluid(imgixParams: { duotone: "0000F9,FFFFFF" }, maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`
