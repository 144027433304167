import React from 'react'
import Image from 'gatsby-image'

class NewsItem extends React.Component {
  constructor() {
    super()
    this.state = { excerpt: true }
    this.expand = () => {
      this.setState({ excerpt: !this.state.excerpt })
    }
  }

  render() {
    return (
      <li className="newsitem">
        <article>
          {this.props.data.image ? (
            <Image fluid={this.props.data.image.fluid} />
          ) : null}
          <time dateTime={this.props.data.number_date} className="date">
            {this.props.data.text_date}
          </time>
          <div
            className={this.state.excerpt ? `entry excerpt` : `entry`}
            dangerouslySetInnerHTML={{ __html: this.props.data.text.html }}
          />
          <button className="readmore" onClick={this.expand}>
            {this.state.excerpt ? `Read all` : ``}
          </button>
        </article>
      </li>
    )
  }
}

export default NewsItem
